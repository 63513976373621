<script setup lang="ts">
import type { WishlistFormatted } from '@/repository/entities/wishlist'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    houseName?: string
    list: WishlistFormatted[]
    loaded: boolean
    modelValue?: string | null
  }>(),
  {
    houseName: '',
    loaded: false,
    modelValue: null,
  },
)
const emits = defineEmits<{
  'update:model-value': [value: string | null]
}>()

const showLegacyMessage = computed(
  () => props.list.length === 1 && props.list.some((l) => l.legacy),
)
const showBannerMessage = computed(
  () => props.list.length === 0 || showLegacyMessage.value,
)

const value = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emits('update:model-value', val)
  },
})

const getTitle = (legacy: boolean, title: string) => {
  return legacy && title === 'My wishlist'
    ? t('wishlistMultiple.allMyFavourite')
    : title
}
</script>

<template>
  <div>
    <div class="mb-6 text-xl font-bold">
      {{ $t('wishlistMultiple.addToTheList', { houseName }) }}
    </div>

    <template v-if="loaded">
      <WishlistMultipleBannerAdd v-if="showBannerMessage" class="mb-6" />

      <div v-if="showLegacyMessage" class="mb-2 text-md text-gray-500">
        {{ $t('wishlistMultiple.textLegacyWishlist') }}
      </div>
    </template>

    <div class="mb-9 last:mb-0 tablet:mb-4">
      <label
        v-for="(item, index) in list"
        :key="index"
        class="mb-4 w-full last:mb-0"
      >
        <input
          v-model="value"
          :value="item.privateToken"
          class="hidden"
          type="radio"
        />
        <WishlistMultipleCardRadio
          :checked="item.privateToken === value"
          :house-count="item.houseCount"
          :images="item.images"
          :title="getTitle(item.legacy, item.title)"
        />
      </label>
    </div>
  </div>
</template>
